<template>
  <service-template
    type="audio"
    service="asa"
    :run-display-text="runDisplayText"
    :loading="isLoading"
    :run-disabled="selected !== inputOptions.mic && !blob"
    :run="selected === inputOptions.mic ? null : handleRun"
    :icon="require('@/assets/images/icons/services/header/ASR.svg')"
  >
    <template v-slot:header>
      {{ $t('asr.header') }}
    </template>
    <template v-slot:left>
      <div class="asr-service">
        <input-language
          :step="1"
          :languages="availableLanguages"
          :current-language.sync="lang"
          :loading="false"
        />
        <samples-carousel
          :samples="samplesList"
          :index="index"
          :disabled="selected !== null"
          @click="handleSampleClick"
        />
        <step-heading
          v-if="isAuthenticated()"
          :step="0"
          :title="$t(`services.ownVoice`)"
        />
        <div
          v-if="isAuthenticated()"
          class="asr-input-container grid grid-cols-2"
        >
          <audio-stream
            ref="openMic"
            :icons="{
              on: require('@/assets/images/icons/mic-green-on.svg'),
              off: require('@/assets/images/icons/mic-green-off.svg'),
            }"
            raw
            :lang="lang"
            :class="{ selected: selected === inputOptions.mic }"
            class="asr-input-option flex flex-1 flex-col flex-center"
            :on-response="handleResponseASR"
            :on-open="handleMicOpen"
            @click="handleMicAction"
          >
            <span class="asr-input-option-label">{{ $t(`asr.clickMic`) }}</span>
          </audio-stream>
          <audio-upload
            :selected="selected === inputOptions.file"
            :disabled="selected === inputOptions.mic"
            :allowed-formats="allowedFormats"
            :on-upload="handleFileChange"
          />
        </div>
        <div
          v-if="selected === inputOptions.mic"
          class="waves-container flex flex-center flex-col"
        >
          <button
            class="delete"
            @click="() => $refs.openMic.$el.click()"
          >
            <img
              :src="require('@/assets/images/icons/delete.svg')"
              alt="delete"
            >
          </button>
          <img :src="require('@/assets/images/icons/waves.svg')">
          <span class="timer">{{ timerTextDisplay }}</span>
        </div>
        <audio-player
          v-if="selected !== inputOptions.mic"
          :url="path"
          :blob="blob"
          :placeholder="$t(`placeholder['${isAuthenticated() ? 'Audio preview' : 'Selected audio'}']`)"
          @delete="handleDelete"
        />
      </div>
    </template>
    <template v-slot:right>
      <generated-result
        :step="3"
        :title="$t('asr.header')"
        :service-name="$t('asr.header')"
        :copyable-text="asrMixin.message"
      >
        <div
          v-if="asrMixin.message"
          :class="{ rtl: lang === $consts.lang.AR }"
        >
          {{ asrMixin.message }}
        </div>
      </generated-result>
    </template>
  </service-template>
</template>

<script>
import { mapGetters } from 'vuex';
import AudioFile from '@/service/audio/file';
import { isSafari, isIE } from '@/libs/utils';
import { floatSecondsToHHMMSS } from '@/libs/date';
import AudioPlayer from '@/components/AudioPlayer/Index.vue';
import ASR from '@/service/api/asa/automaticSpeechRecognition';
import StepHeading from '@/views/pages/AIServices/components/StepHeading/Index.vue';
import SamplesCarousel from '@/views/pages/AIServices/ASA/SamplesCarousel.vue';
import InputLanguage from '@/views/pages/AIServices/components/InputLanguage/Index.vue';
import ServiceTemplate from '@/views/pages/AIServices/components/ServiceTemplate/Index.vue';
import GeneratedResult from '@/views/pages/AIServices/components/GeneratedResult/Index.vue';
import ErrorService from '@/service/utils/error';
import AudioUpload from './AudioUpload.vue';
import AudioStream from './AudioStream.vue';
import asrResponseMixin from './response.mixin';

export default {
  components: {
    ServiceTemplate,
    GeneratedResult,
    InputLanguage,
    SamplesCarousel,
    AudioPlayer,
    StepHeading,
    AudioStream,
    AudioUpload,
  },
  mixins: [asrResponseMixin],
  data() {
    return {
      isLoading: false,
      index: -1,
      isStreaming: false,
      samples: {},
      availableLanguages: [this.$consts.lang.EN, this.$consts.lang.AR],
      lang: this.$consts.lang.EN,
      path: '',
      blob: null,
      asr: null,
      audio: null,
      time: 0,
      seek: 0,
      interval: null,
      selected: null,
      openingStream: false,
      inputOptions: {
        samples: null,
        mic: 0,
        file: 1,
      },
    };
  },
  computed: {
    ...mapGetters(['isAuthenticated']),
    allowedFormats() {
      return this.$consts.config.ASR.ALLOWED_FORMATS.join(',');
    },
    timerTextDisplay() {
      return floatSecondsToHHMMSS(this.time);
    },
    isDisabled() {
      return this.isLoading || !this.path;
    },
    runDisplayText() {
      if (!this.isStreaming) return this.$t('button.run');

      return this.$t('button.pause');
    },
    samplesList() {
      const samples = this.samples[this.lang];

      if (!samples) return [];

      return samples.map((sample) => ({ ...sample, path: `${this.$consts.config.CDN.SERVER}${sample.path}` }));
    },
  },
  watch: {
    lang() {
      this.handleDelete();
    },
  },
  beforeDestroy() {
    this.handleDelete();
  },
  async mounted() {
    this.samples = await this.$api.asa.asr.getSampleList();
  },
  methods: {
    handleMicOpen() {
      this.time = 0;
      const interval = setInterval(() => {
        this.time += 1;
      }, 1000);
      this.interval = interval;
      this.openingStream = false;
    },
    handleMicAction() {
      if (this.openingStream) return;

      if (this.interval) {
        this.time = 0;
        clearInterval(this.interval);
        this.interval = null;
      } else {
        this.handleDelete();
        this.openingStream = true;
      }

      this.handleInputAction(0);
    },
    handleInputAction(value) {
      this.timer = 0;
      this.index = -1;
      this.selected = this.selected !== null && this.selected === value ? null : value;
    },
    handleDelete({ clearSelection = true } = {}) {
      if (this.asr) this.asr.end();
      if (this.audio) this.audio.disable();
      if (clearSelection) {
        this.path = '';
        this.resetASR();
        this.index = -1;
        this.blob = null;
        this.selected = this.inputOptions.samples;
      }

      this.asr = null;
      this.audio = null;
      this.isStreaming = false;
    },
    handleFileChange(file) {
      if (!file) return this.handleInputAction(null);

      this.handleDelete();

      if (this.selected !== this.inputOptions.file) this.handleInputAction(1);

      this.blob = file;

      return null;
    },
    handleSampleClick(audio, index) {
      this.handleDelete();
      this.selected = null;

      if (this.index === index) return;

      this.$nextTick(async () => {
        this.index = index;
        this.path = audio.path;
        const { data } = await this.$api.util.getFileAsBlob(audio.path);
        this.blob = data;
      });
    },
    handlePlayPause() {
      if (!this.audio) return null;

      this.isStreaming = true;
      if (this.audio.isPaused) return this.audio.play();

      this.isStreaming = false;
      return this.audio.pause();
    },
    async handleRun() {
      if (isSafari || isIE) return ErrorService.displayInfoAlert('If you\'d like to maximize the usage of the features in our Demo, we suggest you to use browsers other than IE and Safari');

      if (this.audio) return this.handlePlayPause();

      this.isLoading = true;
      this.resetASR();

      const asr = new ASR({ onResponse: this.handleResponseASR });
      asr.setConfig({ lang: this.lang });

      try {
        asr.start();
        await asr.onReady();
      } catch (ex) {
        ErrorService.displayErrorAlert('Unable to make connection to the server');
        this.isLoading = false;
        this.isStreaming = false;
        return null;
      }

      const audio = new AudioFile({ desiredSampleRate: asr.sampleRate, blob: this.blob });
      audio.onStream = asr.sendAudioData;
      audio.onEnded = () => this.handleDelete({ clearSelection: false });
      audio.play();

      this.asr = asr;
      this.audio = audio;
      this.isLoading = false;
      this.isStreaming = true;

      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
.asr-service {
  .asr-input-container {
    gap: 0.2rem;
    margin: 0.16rem 0;

    .asr-input-option {
      min-height: 1.4rem;
      border-radius: 4px;
      border: 1px dashed #14D4B2;
      padding: 0.12rem;

      .asr-input-option-label {
        margin-top: 0.16rem;
        font-size: 0.14rem;
        color: $text-primary-dimmed;
      }

      &.selected {
        background: #14D4B2;
        .asr-input-option-label {
          color: white;
        }
      }
    }
  }

  .waves-container {
    position: relative;
    width: 100%;
    height: 1.8rem;
    background: #F3F6FF;

    .delete {
      position: absolute;
      top: 0.12rem;
      right: 0.12rem;
    }

    .timer {
      margin-top: 0.12rem;
    }
  }
}
</style>
